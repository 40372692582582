"use client";
import { TypeBannerSkeleton } from "@contentful/types";
import { Banner } from "@yardzen/ui";
import { Entry } from "contentful";
import Cookies from "js-cookie";
import { usePathname, useSearchParams } from "next/navigation";
import { useCallback, useEffect, useState } from "react";

export function AnnouncementBanner({
  contentfulBanner,
}: {
  contentfulBanner: Entry<
    TypeBannerSkeleton,
    "WITHOUT_UNRESOLVABLE_LINKS",
    string
  > | null;
}) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [isMounted, setIsMounted] = useState(false);

  const [discountCodeCookie, setDiscountCodeCookie] = useState<
    string | undefined
  >(undefined);
  const [hideDiscountCodeBanner, setHideDiscountCodeBanner] = useState<
    string | undefined
  >(undefined);
  const [hideAnnouncementBanner, setHideAnnouncementBanner] = useState<
    string | undefined
  >(undefined);
  const [currentBannerVariant, setCurrentBannerVariant] = useState<
    "promo" | "announcement" | "hidden"
  >("hidden");

  const announcementBanner = contentfulBanner;
  const productionToggle = announcementBanner?.fields.active;
  const stagingToggle = searchParams.get("banner") === "true";
  const announcementToggle = productionToggle || stagingToggle;

  const onBannerClose = useCallback(
    (variant: string) => {
      const setHostName = window.location.hostname;
      if (variant === "promo") {
        Cookies.set("yzhpb", "true", {
          domain: setHostName === "localhost" ? "localhost" : ".yardzen.com",
        });
        setHideDiscountCodeBanner(Cookies.get("yzhpb"));
        if (announcementToggle && !hideAnnouncementBanner) {
          setCurrentBannerVariant("announcement");
        } else {
          setCurrentBannerVariant("hidden");
        }
      }
      if (variant === "announcement") {
        Cookies.set("yzhab", "true", {
          domain: setHostName === "localhost" ? "localhost" : ".yardzen.com",
        });
        setHideAnnouncementBanner(Cookies.get("yzhab"));
        if (discountCodeCookie && !hideDiscountCodeBanner) {
          setCurrentBannerVariant("promo");
        } else {
          setCurrentBannerVariant("hidden");
        }
      }
    },
    [
      announcementToggle,
      discountCodeCookie,
      hideAnnouncementBanner,
      hideDiscountCodeBanner,
    ],
  );

  // Mount effect
  useEffect(() => {
    setIsMounted(true);
  }, []);

  // Cookie effect - only runs after mount
  useEffect(() => {
    if (!isMounted) return;

    setHideDiscountCodeBanner(Cookies.get("yzhpb"));
    setDiscountCodeCookie(Cookies.get("yzpdc"));
    setHideAnnouncementBanner(Cookies.get("yzhab"));

    if (
      (announcementToggle && hideAnnouncementBanner) ||
      (discountCodeCookie && hideDiscountCodeBanner)
    ) {
      setCurrentBannerVariant("hidden");
    }

    if (announcementToggle && !hideAnnouncementBanner) {
      setCurrentBannerVariant("announcement");
    }

    if (discountCodeCookie && !hideDiscountCodeBanner) {
      setCurrentBannerVariant("promo");
    }
  }, [
    isMounted,
    announcementToggle,
    discountCodeCookie,
    hideAnnouncementBanner,
    hideDiscountCodeBanner,
  ]);

  if (!contentfulBanner) {
    console.log("Failed to load announcement banner.");
    return null;
  }

  function shouldShowBanner() {
    if (
      pathname === "/" ||
      pathname === "/home" ||
      pathname === "/packages" ||
      pathname === "/how-it-works" ||
      pathname === "/full-yard" ||
      pathname === "/front-yard" ||
      pathname === "/back-yard" ||
      pathname === "/curb-appeal" ||
      pathname === "/outdoor-transformation" ||
      pathname === "/botanical" ||
      pathname === "/premium" ||
      pathname === "/design-consultation" ||
      pathname === "/gallery" ||
      pathname === "/5-reasons-to-choose-yardzen" ||
      pathname === "/studio-mcgee"
    ) {
      return true;
    } else {
      return false;
    }
  }

  return (
    shouldShowBanner() &&
    currentBannerVariant !== "hidden" && (
      <Banner
        variant={currentBannerVariant}
        promoCode={discountCodeCookie}
        bannerContent={contentfulBanner}
        onBannerClose={onBannerClose}
      />
    )
  );
}

export default AnnouncementBanner;
